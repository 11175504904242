/**
 * MEDIA CAROUSEL
 * @description : Swiper for media (image/video) carousels
 */

(function () {

    var mediaCarouselEls = document.querySelectorAll('.js-media-carousel');

    mediaCarouselEls.forEach(function (mediaCarouselEl) {

        var autoplayEnabled = mediaCarouselEl.classList.contains('js-media-carousel--autoplay');

        var swiperEl = mediaCarouselEl.querySelector('.js-media-carousel__swiper');
        var slideEls = mediaCarouselEl.querySelectorAll('.js-media-carousel__slide');

        var containsMap = Boolean(swiperEl.querySelector('.js-map'));

        if (swiperEl && slideEls.length > 1) {
            var paginationEl = mediaCarouselEl.querySelector('.js-media-carousel__pagination');

            var prevButtonEl = mediaCarouselEl.querySelector('.js-media-carousel__prev');
            var nextButtonEl = mediaCarouselEl.querySelector('.js-media-carousel__next');

            var swiper = new Swiper(swiperEl, {
                spaceBetween: 8,
                slidesPerView: 1,
                direction: 'horizontal',
                loop: !containsMap,
                autoplay: autoplayEnabled ? ({ delay: 6000 }) : false,
                slidesPerView: 1,
                direction: 'horizontal',
                speed: 500,
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    clickable: true,
                    bulletClass: 'c-carousel-pagination__dot',
                    bulletActiveClass: 'c-carousel-pagination__dot--active'
                },
                navigation: {
                    nextEl: nextButtonEl,
                    prevEl: prevButtonEl,
                },
                breakpoints: {
                    [window.breakpoints['lg']]: {
                        speed: 750
                    }
                }
            });

            swiper.on('slideChange', function () {
                // Load all lazyloading images straight away once the first slide has passed.
                var lazyLoadingMediaEls = swiperEl.querySelectorAll('[loading]');
                if (lazyLoadingMediaEls.length) {
                    lazyLoadingMediaEls.forEach(el => {
                        el.removeAttribute('loading');
                    });
                }
            });

        }

    });
})();